import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../menu/Menu.css';

const MenuNoLogueado = () => {
  return (
    <nav className="menu">
      <Nav className="py-2 flex-column flex-sm-row">
        <Nav.Item>
          <Nav.Link as={Link} to="/" className="text-light">Inicio</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/calibrar" className="text-light">Calibrar</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/mantenimiento" className="text-light">Mantenimiento</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/supervision" className="text-light">Supervisión</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} to="/contacto" className="text-light">Contactos</Nav.Link>
        </Nav.Item>
      </Nav>
    </nav>
  );
};

export default MenuNoLogueado;
