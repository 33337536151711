import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'; 
import api from '../auth/Auth'; 
import Loader from './Loader.js'; // Importa el componente Loader

const CrearContrasena = ({ token, correo, setShowForm }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userIP, setUserIP] = useState('');
  const [loading, setLoading] = useState(false); // Nuevo estado para el Loader
  const navigate = useNavigate(); 

  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setUserIP(data.ip);
      } catch (error) {
        console.error('Error al obtener la IP:', error);
      }
    };

    fetchIP();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (password !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden.');
      return;
    }

    if (!passwordRegex.test(password)) {
      setErrorMessage(
        'La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un carácter especial.'
      );
      return;
    }

    const apiUrl = '/usuarios/restablecer-contrasena';
    const payload = {
      correo: correo,
      contrasena: password,
      ipCambio: userIP,
      metodoCambio: "Restablecer"
    };

    setLoading(true); // Mostrar el Loader cuando empieza la solicitud
    try {
      const response = await api.post(apiUrl, payload);

      if (response.data) {
        if (response.data.codigo === 1) {
          navigate('/validado-ok-Restaura');
        } else {
          setErrorMessage(response.data.mensaje);
        }
      } else {
        setErrorMessage('Error al crear la contraseña. Por favor, intenta de nuevo.');
      }
    } catch (error) {
      setErrorMessage('Ocurrió un error al intentar crear la contraseña.');
    } finally {
      setLoading(false); // Ocultar el Loader cuando la solicitud termina
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ paddingTop: '20px' }}>
      <div className="col-md-4">
        <h2 className="text-center">Reestablecer tu Contraseña</h2>

        {loading ? ( // Mostrar el Loader mientras loading es true
          <Loader />
        ) : (
          <>
            {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}
            {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">Nueva Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Ingresa tu nueva contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="form-group mt-3">
                <label htmlFor="confirmPassword">Confirmar Contraseña:</label>
                <input
                  type="password"
                  className="form-control"
                  id="confirmPassword"
                  placeholder="Confirma tu nueva contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary mt-4 w-100">
                Crear Contraseña
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

const ReestablecerContrasena = () => {
  const { token } = useParams();
  const [responseMessage, setResponseMessage] = useState('');
  const [codigo, setCodigo] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true); // Nuevo estado para el Loader
  const navigate = useNavigate(); 

  useEffect(() => {
    const verificarUsuario = async () => {
      const url = `/usuarios/verificar?token=${token}`;

      try {
        const response = await api.get(url);
        setCodigo(response.data.codigo);
        setResponseMessage(response.data.mensaje);

        if (response.data.codigo === 1) {
          setShowForm(true);
          setUserEmail(response.data.email);
        }
      } catch (error) {
        console.error("Error al verificar el token:", error);
        setResponseMessage("Ocurrió un error al intentar verificar el token.");
        setCodigo(0);
      } finally {
        setLoading(false); // Ocultar el Loader cuando la solicitud termina
      }
    };

    verificarUsuario();
  }, [token]);

  const solicitarNuevoToken = async () => {
    const url = `/usuarios/solicitar-nuevo-token?token=${token}`;

    setLoading(true); // Mostrar el Loader mientras la API está en progreso
    try {
      const response = await api.post(url);
      
      if (response.data.codigo === 1) {
        setTimeout(() => {
          navigate('/verificar-correo');
        }, 10);
      } else {
        setResponseMessage(response.data.mensaje);
      }
    } catch (error) {
      setResponseMessage('Ocurrió un error al solicitar el nuevo token.');
    } finally {
      setLoading(false); // Ocultar el Loader cuando termina la solicitud
    }
  };

  return (
    <div className="container mt-4">
      {loading ? (
        <Loader /> // Mostrar el Loader mientras loading es true
      ) : (
        <>
          {responseMessage && <div className="alert alert-info mt-3">{responseMessage}</div>}
          {codigo === 1 && showForm ? (
            <CrearContrasena token={token} correo={userEmail} setShowForm={setShowForm} />
          ) : codigo === 2 ? (
            <div className="alert alert-warning mt-3">El token ha expirado. Por favor, solicita uno nuevo.</div>
          ) : codigo === 3 ? (
            <div className="alert alert-danger mt-3">
              El token es inválido o ha expirado. <Link to="#" onClick={solicitarNuevoToken}>Solicitar nuevo token</Link>
            </div>
          ) : codigo === 0 ? (
            <div className="alert alert-danger mt-3">Token inválido. Verificación fallida.</div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ReestablecerContrasena;
