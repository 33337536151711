import React, { useState } from 'react';
import axios from '../auth/Auth'; // Importa la configuración de axios desde auth.js
import 'bootstrap/dist/css/bootstrap.min.css';
import ReCAPTCHA from 'react-google-recaptcha'; // Importa el componente reCAPTCHA
import Loader from '../components/Loader'; // Importa tu componente de Loader

const OlvidoContrasena = () => {
    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false); // Estado para verificar el CAPTCHA
    const [isLoading, setIsLoading] = useState(false); // Estado para manejar el loader

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMensaje(''); // Reiniciar mensaje

        // Verifica que el CAPTCHA haya sido completado
        if (!captchaVerified) {
            setMensaje('Por favor verifica que no eres un robot.');
            return;
        }

        // Validar el formato del correo
        if (!validateEmail(correo)) {
            setMensaje('Por favor ingresa un correo electrónico válido.');
            return;
        }

        // Preparar el payload
        const payload = {
            tipo: 1, // Siempre correo
            valor: correo, // El valor del correo electrónico
        };

        const apiUrl = '/usuarios/olvido-contrasena';

        setIsLoading(true); // Muestra el loader

        try {
            const response = await axios.post(apiUrl, payload); // Utiliza la configuración de axios importada desde auth.js
            const { data } = response;

            // Verifica el código de la respuesta
            if (data?.codigo === 1) {
                setMensaje('Se ha enviado un enlace para restablecer tu contraseña.');
            } else {
                setMensaje(data?.mensaje || 'Error al enviar el enlace');
            }
        } catch (error) {
            setMensaje(error.response?.data?.message || 'Error al enviar el enlace');
        } finally {
            setIsLoading(false); // Oculta el loader
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular básica para validar un correo electrónico
        return regex.test(email);
    };

    const onCaptchaChange = (value) => {
        setCaptchaVerified(!!value); // Verifica si el CAPTCHA fue resuelto
    };

    return (
        <div className="container mt-5 d-flex justify-content-center">
            <div className="col-md-4">
                <h2 className="text-center">Olvidé mi Contraseña</h2>
                
                {/* Mostrar el loader mientras espera la respuesta de la API */}
                {isLoading ? (
                    <Loader /> // Mostrar el loader mientras se está cargando
                ) : (
                    <>
                        {mensaje && <div className="alert alert-info text-center">{mensaje}</div>}
                        <form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow">
                            <div className="mb-3">
                                <label htmlFor="correo" className="form-label">Correo Electrónico</label>
                                <input
                                    type="email"
                                    id="correo"
                                    className="form-control"
                                    value={correo}
                                    onChange={(e) => setCorreo(e.target.value)}
                                    required
                                />
                            </div>

                            {/* CAPTCHA */}
                            <div className="mb-3">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Obtiene la clave del reCAPTCHA desde .env
                                    onChange={onCaptchaChange}
                                />
                            </div>

                            <button type="submit" className="btn btn-primary w-100">Enviar Enlace</button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default OlvidoContrasena;
