import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';

const CdaSelection = ({ cdas, setSelectedCda }) => {
  const [selectedCda, setSelectedCdaState] = useState('');
  const navigate = useNavigate();

  const handleCdaSelect = () => {
    if (selectedCda) {
      setSelectedCda(selectedCda);
      navigate('/');
    }
  };

  return (
    <div className="selection-container">
      <h2>Nos alegra verte de nuevo.</h2>
      <Form>
        <Form.Group controlId="cdaSelect">
          <Form.Label>Por favor, elige el CDA con el que te gustaría gestionar tus tareas en esta ocasión:</Form.Label>
          <Form.Control as="select" value={selectedCda} onChange={(e) => setSelectedCdaState(e.target.value)}>
            <option value="">Seleccione un CDA</option>
            {cdas.map((cda) => (
              <option key={cda.cda.id} value={cda.cda.id}>{cda.cda.nombre}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Form>
      <Button variant="primary" onClick={handleCdaSelect}>
        Aceptar
      </Button>
    </div>
  );
};

export default CdaSelection;