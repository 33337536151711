import React from 'react';
import { Card } from 'react-bootstrap';

// Datos de noticias
const news = [
  {
    title: "Reglas del Servicio de Acreditación",
    description: "Descargue la versión 10 de las reglas de acreditación de ONAC.",
    link: "https://onac.org.co/documentos/rac-3-0-01-antes-r-ac-01/",
  },
  {
    title: "Calcomanía de Como Conduzco",
    description: "Resolución 6462 del 28 de junio de 2024",
    link: "https://www.supertransporte.gov.co/documentos/2024/Junio/Comunicaciones_29/Resolucion_6462.pdf",
  },
  // Puedes agregar más noticias aquí
];

const Noticias = () => {
  return (
    <Card className="shadow-lg bg-dark text-light">
      <Card.Header as="h5">Noticias Recientes</Card.Header>
      <Card.Body>
        {news.map((item, index) => (
          <div key={index} className="mb-3">
            <Card.Text>
              <strong>{item.date}</strong> <a href={item.link} target="_blank" rel="noopener noreferrer" className="text-info">{item.title}</a>
            </Card.Text>
            <Card.Text>{item.description}</Card.Text>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default Noticias;
