import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { 
  setTokens, 
  getAccessToken, 
  getRefreshToken, 
  getAccessTokenExpiration, 
  refreshAccessToken, 
  clearTokens as clearStoredTokens 
} from '../auth/Auth';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
    tokenExpiration: getAccessTokenExpiration(),
    isAuthenticated: !!getAccessToken(), // Verificar autenticación al cargar
    user: null, // Almacenamos el usuario aquí
  });

  const [lastActivityTime, setLastActivityTime] = useState(Date.now()); // Estado para el tiempo de la última actividad

  const extractUserFromToken = (token) => {
    try {
      const payload = token.split('.')[1];
      if (!payload) throw new Error("Token malformado: falta payload");
      const decodedPayload = JSON.parse(atob(payload));
      return {
        nombre: decodedPayload.nombre,
        rol: decodedPayload.rol,
        id : decodedPayload.id,
        correo  : decodedPayload.correo,
        nombrecomp : decodedPayload.nombrecomp,
        nrol  : decodedPayload.nrol,
        vigente : decodedPayload.vigente,
        fvigente  : decodedPayload.fvigente,
        cdaNombre : decodedPayload.cdaNombre,
        cdaCodigo : decodedPayload.cdaCodigo,
        cdaId : decodedPayload.cdaId,
      };
    } catch (error) {
      console.error("Error al decodificar el token:", error);
      return null;
    }
  };

  const refreshToken = useCallback(async () => {
    try {
      const newAccessToken = await refreshAccessToken();
      if (newAccessToken && newAccessToken !== authState.accessToken) { // Solo actualizar si el token ha cambiado
        const user = extractUserFromToken(newAccessToken);
        setAuthState((prevState) => ({
          ...prevState,
          accessToken: newAccessToken,
          tokenExpiration: getAccessTokenExpiration(),
          isAuthenticated: true,
          user: user,
        }));
      } else if (!newAccessToken) {
        logout();
      }
    } catch (error) {
      console.error("Error al refrescar el token:", error);
      logout();
    }
  }, [authState.accessToken]);

  useEffect(() => {
    const checkTokenExpiration = async () => {
      const expirationTime = getAccessTokenExpiration();
      const timeRemaining = expirationTime - Date.now();
      if (timeRemaining <= 5 * 60 * 1000) { // Renueva el token 5 minutos antes de que expire
        await refreshToken();
      } else {
        setTimeout(refreshToken, timeRemaining - 5 * 60 * 1000);
      }
    };

    const handleUserActivity = () => {
      setLastActivityTime(Date.now());
    };

    const checkInactivity = () => {
      const currentTime = Date.now();
      if (currentTime - lastActivityTime >= 15 * 60 * 1000) { // 15 minutos de inactividad
        logout();
      }
    };

    let activityTimeout;
    let inactivityInterval;

    const startInactivityCheck = () => {
      clearInterval(inactivityInterval);
      inactivityInterval = setInterval(checkInactivity, 60 * 1000); // Verifica cada minuto
    };

    const resetActivityTimeout = () => {
      clearTimeout(activityTimeout);
      activityTimeout = setTimeout(handleUserActivity, 60 * 1000); // Resetea el timer de actividad
    };

    if (authState.accessToken) {
      checkTokenExpiration();
      const user = extractUserFromToken(authState.accessToken);
      if (user) {
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          user: user,
        }));
      }
      document.addEventListener('mousemove', resetActivityTimeout);
      document.addEventListener('keypress', resetActivityTimeout);
      startInactivityCheck();
    }

    return () => {
      clearTimeout(activityTimeout);
      clearInterval(inactivityInterval);
      document.removeEventListener('mousemove', resetActivityTimeout);
      document.removeEventListener('keypress', resetActivityTimeout);
    };
  }, [authState.accessToken, lastActivityTime, refreshToken]);

  const login = (token, refreshToken, expiresIn) => {
    const user = extractUserFromToken(token);
    setTokens(token, refreshToken, expiresIn);
    setAuthState({
      accessToken: token,
      refreshToken: refreshToken,
      tokenExpiration: getAccessTokenExpiration(),
      isAuthenticated: true,
      user: user,
    });
  };

  const logout = () => {
    clearStoredTokens();
    setAuthState({
      accessToken: null,
      refreshToken: null,
      tokenExpiration: null,
      isAuthenticated: false,
      user: null,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        login,
        logout,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};