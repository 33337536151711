// src/rutas/ProtectedRoutes.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contex/AuthContext'; // Asegúrate de tener el contexto AuthContext configurado

const ProtectedRoutes = () => {
  const { authState } = useAuth(); // Usamos el estado de autenticación del contexto

  // Si el usuario no está autenticado, redirigimos a la página de login
  if (!authState.isAuthenticated) {
    return <Navigate to="/login" />;
  }
  // Si está autenticado, se renderiza el contenido de las rutas protegidas
  return <Outlet />;
};

export default ProtectedRoutes;