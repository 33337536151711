import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { setTokens } from '../auth/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from '../contex/AuthContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { Modal, Button, Form } from 'react-bootstrap';
import logometro from '../assets/images/METROCDA.png';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './Login.css';

const Login = () => {
  const [correo, setCorreo] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [nombreUsuario, setNombreUsuario] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [cdas, setCdas] = useState([]);
  const [showCdaModal, setShowCdaModal] = useState(false);
  const [selectedCda, setSelectedCda] = useState('');

  const logApiCalls = process.env.REACT_APP_LOG_API_CALLS === 'true';
  const { login } = useAuth();
  const navigate = useNavigate();

  const log = (message, data) => {
    if (logApiCalls) {
      console.log(message, data);
    }
  };

  const decodeToken = (token) => {
    try {
      const payloadBase64 = token.split('.')[1];
      const payload = atob(payloadBase64);
      const utf8Payload = decodeURIComponent(escape(payload));
      return JSON.parse(utf8Payload);
    } catch (error) {
      console.error('Error al decodificar el token:', error);
      return null;
    }
  };

  const obtenerIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      log('IP pública obtenida:', data.ip);
      return data.ip;
    } catch (error) {
      console.error('Error al obtener la IP pública:', error);
      return '';
    }
  };

  const registerSession = async (token) => {
    try {
      const decodedToken = decodeToken(token);
      if (decodedToken) {
        const { id, rol } = decodedToken;
        const ip4 = 'N/A';
        const ip6 = await obtenerIP();
        const sessionPayload = {
          usuario: id,
          rol: rol || 1,
          fechaHora: new Date().toISOString(),
          ip4: ip4,
          ip6: ip6,
        };

        log('Enviando los siguientes datos al endpoint /app/sesiones:', sessionPayload);

        await api.post('/app/sesiones', sessionPayload);
        console.log('Sesión registrada exitosamente.');
      }
    } catch (error) {
      console.error('Error al registrar la sesión:', error);
    }
  };

  const obtenerCdas = async (correo) => {
    try {
      const endpoint = `app/v1/usuario-cda/buscar-por-correo?correo=${correo}`;
      log('Llamando al endpoint:', endpoint);
      const response = await api.get(endpoint);
      log('Respuesta recibida del endpoint:', response.data);
      const cdasActivos = response.data.filter(cda => cda.cda.activo);
      log('CDAs activos:', cdasActivos);
      return cdasActivos;
    } catch (error) {
      console.error('Error al obtener los CDAs:', error);
      return [];
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMensaje('');

    if (!captchaVerified) {
      setMensaje('Por favor verifica que no eres un robot.');
    //  return;
    }

    const payload = { correo, contrasena };
    log('Intentando iniciar sesión con payload:', payload);

    try {
      const response = await api.post('/public/v1/login', payload);
      log('Respuesta de login:', response.data);
      const { token, refreshToken } = response.data;

      if (token && refreshToken) {
        setTokens(token, refreshToken);
        login(token, refreshToken, response.data.expiresIn);

        const decodedToken = decodeToken(token);
        if (!decodedToken) {
          setNombreUsuario(decodedToken.nombre || 'Usuario');
          const { id, rol } = decodedToken;

          if ([3, 4, 5, 6].includes(rol)) {
            const cdasVinculados = await obtenerCdas(correo);
            log('CDAs vinculados:', cdasVinculados);
            if (cdasVinculados.length > 1) {
              setCdas(cdasVinculados);
              setShowCdaModal(true);
              return;
            }
          }
        }

        await registerSession(token);
        setShowModal(true);
        window.scrollTo(0, 0);

        setTimeout(() => {
          handleModalClose();
        }, 15000);

        navigate('/');
      } else {
        setMensaje('No se pudo iniciar sesión.');
      }
    } catch (error) {
      setMensaje('Error al iniciar sesión. Verifique sus credenciales.');
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate('/');
  };

  const handleCdaSelect = () => {
    setShowCdaModal(false);
    handleModalClose();
  };

  useEffect(() => {
    if (logApiCalls) {
      console.log('Logueo iniciado.');
    }
  }, [logApiCalls]);

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="text-center">Iniciar Sesión</h2>
        {mensaje && <div className={`alert ${mensaje.includes('exitoso') ? 'alert-success' : 'alert-danger'}`}>{mensaje}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="correo" className="form-label">Correo Electrónico</label>
            <input type="email" id="correo" className="form-control" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
          </div>
          <div className="mb-3">
            <label htmlFor="contrasena" className="form-label">Contraseña</label>
            <div className="input-group">
              <input
                type={showPassword ? 'text' : 'password'}
                id="contrasena"
                className="form-control"
                value={contrasena}
                onChange={(e) => setContrasena(e.target.value)}
                required
              />
              <span
                className="input-group-text"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: 'pointer' }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <div className="mb-3">
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onCaptchaChange} />
          </div>
          <button type="submit" className="btn btn-primary w-100">Iniciar Sesión</button>
        </form>
        <div className="text-center mt-3">
          <a href="/olvido-contrasena">¿Olvidaste tu contraseña?</a>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton className="d-flex justify-content-center">
          <Modal.Title>¡Bienvenid@!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
          <img
            src={logometro}
            alt="Bienvenida"
            className="img-fluid mb-3"
            style={{ borderRadius: '30%', maxWidth: '80px', height: 'auto' }}
          />
          <p className="text-center">
            Hola, <strong>{nombreUsuario}</strong>. <br />Nos alegra verte de nuevo.
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="primary" onClick={handleModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCdaModal} onHide={() => setShowCdaModal(false)} centered>
        <Modal.Header closeButton className="d-flex justify-content-center">
          <Modal.Title>Seleccionar CDA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="cdaSelect">
              <Form.Label>Por favor, selecciona el CDA con el que deseas interactuar:</Form.Label>
              <Form.Control as="select" value={selectedCda} onChange={(e) => setSelectedCda(e.target.value)}>
                <option value="">Seleccione un CDA</option>
                {cdas.map((cda) => (
                  <option key={cda.cda.id} value={cda.cda.id}>{cda.cda.nombre}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={() => setShowCdaModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleCdaSelect}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Login;