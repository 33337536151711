import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../menu/MenuAdministrador.css';

const MenuAdministrador = () => (
  <div className="menu-lateral bg-primary text-white p-3">
  <Nav className="flex-column">
    <Nav.Item>
      <Nav.Link as={Link} to="/dashboard">Dashboard</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link as={Link} to="/usuarios">Gestión de Usuarios</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link as={Link} to="/configuracion">Configuración</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link as={Link} to="/logout" className="text-danger">Cerrar Sesión</Nav.Link>
    </Nav.Item>
  </Nav>
</div>
);

export default MenuAdministrador;
