import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, // Base URL desde variables de entorno
});

// Función para manejar los tokens
export const setTokens = (token, refreshToken, expiresIn) => {
  if (token) {
    sessionStorage.setItem('A::T', token);

    // Si existe expiresIn, usarlo para calcular la expiración
    let expirationTime;
    if (expiresIn) {
      expirationTime = Date.now() + expiresIn * 1000;
    }

    if (expirationTime) {
      sessionStorage.setItem('A::T::E', expirationTime.toString());
    }
  }

  if (refreshToken) {
    sessionStorage.setItem('R::T', refreshToken);
  }
};

export const getAccessToken = () => sessionStorage.getItem('A::T');
export const getRefreshToken = () => sessionStorage.getItem('R::T');
export const getAccessTokenExpiration = () => parseInt(sessionStorage.getItem('A::T::E'));

// Refrescar el token si es necesario
export const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();
  const accesToken =  getAccessToken();
  if (!refreshToken) return null;

  try {
    const apiUrl = `${api.defaults.baseURL}/app/v1/refrescar-token`;
    const response = await axios.post(apiUrl, { refreshToken: refreshToken , accesToken: accesToken  }, {
      headers: {
        Authorization: `Bearer ${refreshToken}` // Usar el refreshToken para autorización
      }
    });

    const { accessToken, refreshToken: newRefreshToken, expiresIn } = response.data;
    setTokens(accessToken, newRefreshToken, expiresIn);
    return accessToken;
  } catch (err) {
    console.error('[API CALL] Error al refrescar el token:', err);
    clearTokens(); // Limpiar los tokens
    window.location.href = '/login'; // Redirigir al login si falla el refresco
    return null;
  }
};

// Limpiar los tokens y hacer logout
export const clearTokens = () => {
  sessionStorage.removeItem('A::T');
  sessionStorage.removeItem('R::T');
  sessionStorage.removeItem('A::T::E');
  // Aquí deberías llamar a la función de logout de tu contexto de autenticación
};

// Interceptor de solicitud
api.interceptors.request.use(
  async (config) => {
    let accessToken = getAccessToken();
    const expirationTime = getAccessTokenExpiration();

    // Si el token está por expirar, lo refrescamos
    if (expirationTime && Date.now() >= expirationTime - 60000) { // 1 minuto antes de la expiración
      accessToken = await refreshAccessToken();
    }

    const refreshToken = getRefreshToken();
    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`;
    } else if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    const apiKey = process.env.REACT_APP_API_KEY;
    if (apiKey) {
      config.headers['API-KEY'] = apiKey;
    }

    if (process.env.REACT_APP_LOG_API_CALLS === 'true') {
      console.log(`Solicitud a: ${config.url}`, {
        method: config.method,
        url: config.url,
        data: config.data,
      });
    }

    return config;
  },
  (error) => {
    console.error('[API CALL] Error en la solicitud:', error);
    return Promise.reject(error);
  }
);

// Interceptor de respuesta
api.interceptors.response.use(
  (response) => {
    if (process.env.REACT_APP_LOG_API_CALLS === 'true') {
      console.log('Respuesta recibida:', {
        url: response.config.url,
        data: response.data,
      });
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();

    if (error.response && error.response.status === 401 && refreshToken && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const response = await axios.post(`${api.defaults.baseURL}/app/v1/refrescar-token`, { token: refreshToken }, {
          headers: {
            Authorization: `Bearer ${refreshToken}` // Usar el refreshToken para autorización
          }
        });
        const { accessToken, expiresIn } = response.data;

        setTokens(accessToken, refreshToken, expiresIn);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;

        return api(originalRequest);
      } catch (err) {
        console.error('Error al refrescar el token:', err);
        clearTokens();
        window.location.href = '/login';
        return Promise.reject(err);
      }
    }

    console.error('Error en la respuesta:', error);
    return Promise.reject(error);
  }
);

export default api;

/*
import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

// Funciones para manejar los tokens
const setTokens = (token, refreshToken) => {
  localStorage.setItem('ACCESS_TOKEN', token);
  localStorage.setItem('REFRESH_TOKEN', refreshToken);
};

const getAccessToken = () => localStorage.getItem('ACCESS_TOKEN');
const getRefreshToken = () => localStorage.getItem('REFRESH_TOKEN');

const clearTokens = () => {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('REFRESH_TOKEN');
};

// Interceptor de solicitud
api.interceptors.request.use(
  (config) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    const accessToken = getAccessToken();

    if (config.url.includes('/app') && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else if (apiKey) {
      config.headers['API-KEY'] = apiKey;
    }

    if (process.env.REACT_APP_LOG_API_CALLS === 'true') {
      console.log(`Solicitud a: ${config.url}`, config);
    }

    return config;
  },
  (error) => {
    console.error('Error en la solicitud:', error);
    return Promise.reject(error);
  }
);

// Interceptor de respuesta
api.interceptors.response.use(
  (response) => {
    if (process.env.REACT_APP_LOG_API_CALLS === 'true') {
      console.log('Respuesta recibida:', response);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();

    if (error.response && error.response.status === 401 && refreshToken && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const response = await axios.post(`${api.defaults.baseURL}/refresh`, { token: refreshToken });
        const { accessToken, expiresIn } = response.data;

        setTokens(accessToken, refreshToken, expiresIn);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;

        return api(originalRequest);
      } catch (err) {
        console.error('Error al refrescar el token:', err);
        clearTokens();
        window.location.href = '/login';
        return Promise.reject(err);
      }
    }

    console.error('Error en la respuesta:', error);
    return Promise.reject(error);
  }
);

export { api, setTokens, clearTokens };
export default api;
*/