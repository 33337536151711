import React, { useContext } from 'react'; // Importa React y useContext
import { AuthContext } from '../contex/AuthContext'; // Asegúrate de que la ruta sea correcta

// Importa los diferentes menús según los roles
import MenuAdministrador from '../menu/MenuAdministrador';
import MenuExploradorPremium from '../menu/MenuExploradorPremium';
import MenuOperadorPremium from '../menu/MenuOperadorPremium';
import MenuUsuarioElite from '../menu/MenuUsuarioElite';
import MenuUsuarioPlatino from '../menu/MenuUsuarioPlatino';
import MenuNoLogueado from '../menu/MenuNoLogueado';

const Menu = () => {
  // Obtén el estado de autenticación desde el contexto
  const { authState } = useContext(AuthContext); // Usa el contexto para obtener authState

  // Verifica si el usuario está autenticado y tiene un rol
  if (!authState.isAuthenticated || !authState.role) {
    return <MenuNoLogueado />; // Si no hay usuario o rol, mostrar menú de no logueado
  }

  // Según el rol del usuario, se renderiza el menú correspondiente
  switch (authState.role) {
    case 1:
      return <MenuExploradorPremium />;
    case 2:
      return <MenuOperadorPremium />;
    case 3:
      return <MenuUsuarioElite />;
    case 4:
      return <MenuUsuarioPlatino />;
    case 5:
      return <MenuAdministrador />;
    default:
      return <MenuNoLogueado />; // En caso de rol no reconocido, mostrar menú de no logueado
  }
};

export default Menu;
