import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/headers/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';
import Marquee from './components/Marquee';
import { AuthProvider, useAuth } from './contex/AuthContext';
import RutasPublicas from './rutas/PublicRoutes';
import Rutasprotegidas from './rutas/ProtectedRoutes';
import Error404 from './pages/Error404';
import Supervision from './pages/Supervision';
import CdaSelection from './components/CdaSelection';


import './styles/App.css';

const App = () => {
  const { authState } = useAuth();

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="d-flex">
        <Menu />
        <div className="main-content flex-fill">
          {!authState.isAuthenticated && <Marquee />}
          <main className="container mt-4" style={{ paddingTop: '1px', paddingBottom: '150px' }}>
            <Routes>
              <Route path="/*" element={<RutasPublicas />} />
              <Route element={<Rutasprotegidas />}>
                <Route path="/supervision" element={<Supervision />} />
                <Route path="/cda-selection" element={<CdaSelection />} />
              </Route>
              <Route path="*" element={<Error404 />} />
            </Routes>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const AppWithRouter = () => (
  <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
);

export default AppWithRouter;