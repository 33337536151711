import React, { useContext } from 'react';
import { Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaSignInAlt, FaUserPlus, FaUser, FaDoorOpen } from 'react-icons/fa'; // Import FaDoorOpen icon
import logometro from '../../assets/images/METROCDA1.png';
import logocda from '../../assets/images/METROCDA.png';
import { AuthContext } from '../../contex/AuthContext';
import '../headers/Header.css';

const Header = () => {
  const { authState, logout } = useContext(AuthContext);
  const user = authState.user;

  // Log authState to console
  console.log('AuthState:', authState);

  return (
    <header className="header">
      <Navbar className="bg-gradient w-100" expand="lg">
        <Container>
          {authState.isAuthenticated && [2, 3, 4, 5].includes(user?.rol) ? (
            <Row className="w-100 align-items-center">
              <Col xs={3}>
                <Navbar.Brand href="#home" className="d-flex align-items-center">
                  <img
                    src={logometro}
                    className="logo"
                    alt="logometro"
                  />
                </Navbar.Brand>
              </Col>
              <Col xs={3}>
                <img
                  src={logocda}
                  className="logo"
                  alt="cda"
                />
              </Col>
              <Col xs={3}>
                <div className="text-center">
                  <div>{user?.cdaNombre}</div>
                  <div>{user?.cdaCodigo}</div>
                </div>
              </Col>
              <Col xs={3}>
                <div className="text-end">
                  <p className="mb-0">Bienvenid@ {user?.nombre}</p>
                  <small className="text-muted">Rol: {user?.nrol}</small>
                  <div className="mt-2">
                    <Link to="/perfil" className="label-modern me-2" title="Ver perfil">
                      <FaUser className="me-2" /> Perfil
                    </Link>
                    <div 
                      className="label-modern btn-logout" // Add btn-logout class
                      onClick={logout}
                      title="Cerrar sesión"
                    >
                      <FaDoorOpen /> {/* Use FaDoorOpen icon */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Container className="d-flex justify-content-between align-items-center">
              <Navbar.Brand href="#home" className="d-flex align-items-center">
                <img
                  src={logometro}
                  className="logo-large"
                  alt="logometro"
                />
                <span className="d-none d-lg-inline ms-2">Asegurando las mediciones de los CDA's</span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto text-center">
                  {authState.isAuthenticated ? (
                    <div className="text-end">
                      <p className="mb-0">Bienvenid@ {authState.user?.nombre}</p>
                      <small className="text-muted">{authState.user?.rol}</small>
                      <div className="mt-2">
                        <Link to="/perfil" className="label-modern me-2" title="Ver perfil">
                          <FaUser className="me-2" /> Perfil
                        </Link>
                        <div 
                          className="label-modern btn-logout" // Add btn-logout class
                          onClick={logout}
                          title="Cerrar sesión"
                        >
                          <FaDoorOpen /> {/* Use FaDoorOpen icon */}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Link to="/login" className="btn btn-primary btn-modern me-2">
                        <FaSignInAlt className="me-2" /> Iniciar Sesión
                      </Link>
                      <Link to="/registroinicial" className="btn btn-secondary btn-modern">
                        <FaUserPlus className="me-2" /> Registrarte
                      </Link>
                    </>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          )}
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;