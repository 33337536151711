import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Inicio from '../pages/Inicio';
import Calibrar from '../pages/Calibrar';
import Mantenimiento from '../pages/Mantenimiento';

import RegistroInicial from '../pages/RegistroInicial';
import VerificarCorreo from '../pages/VerificarCorreo';
import ValidadoOk from '../pages/ValidadoOk';
import ValidadoOkOlvido from '../pages/ValidadoOkOlvido';
import VerificarRegistro from '../components/VerificarRegistro';
import PoliticaDatosPersonales from '../pages/PoliticaDatosPersonales';
import Error404 from '../pages/Error404';
import Login from '../components/Login';
import OlvidoContrasena from '../components/OlvidoContrasena';
import ReestablecerContrasena from '../components/ReestablecerContrasena';


const PublicRoutes = () => {
  return (
    <main className="container mt-4" style={{ paddingTop: '150px', paddingBottom: '150px' }}>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<Inicio />} />
        <Route path="/registroinicial" element={<RegistroInicial />} />
        <Route path="/calibrar" element={<Calibrar />} />
        <Route path="/mantenimiento" element={<Mantenimiento />} />
        <Route path="/verificar-correo" element={<VerificarCorreo />} />
        <Route path="/validado-Ok" element={<ValidadoOk />} />
        <Route path="/validado-Ok-Restaura" element={<ValidadoOkOlvido />} />
        <Route path="/verificar-registro/:token" element={<VerificarRegistro />} />
        <Route path="/politica-datos-personales" element={<PoliticaDatosPersonales />} />
        <Route path="/login" element={<Login />} /> {/* Ruta para la página de login */}
        <Route path="/olvido-contrasena" element={<OlvidoContrasena />} /> {/* Ruta para olvido de contraseña */}
        <Route path="/restablecer-contrasena/:token" element={<ReestablecerContrasena />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </main>
  );
};

export default PublicRoutes;
